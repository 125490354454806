import showMessage from "./message";

const Api = {

    // Vars

    Bearer          : null,
    IdReseller      : null,
    IdUser          : null,
    Permissions     : null,
    BusinessName    : null,
    Endpoint        : "https://apilicensing.cmh.it", //window.location.hostname == "localhost" ? "http://localhost:8080" : 

    // Http request

    Get: async (path, require_bearer = true, toast = null, custom_message = null) => {

        try{

            const response = 
                await fetch(
                    `${Api.Endpoint}${path}`,
                    {
                        headers: !require_bearer ? {} : {'Authorization': Api.Bearer}
                    }
                );
            if(response.status == 200){
                const json = await response.json();
                if(json.status){
                    return json;
                }
            }

        }catch(e){
            console.log("Error", e);
        }

        if(toast){
            showMessage(toast, "error", custom_message ?? "Si è verificato un errore durante la richiesta. Riprova..");
        }

        return null;
    },

    Post: async (path, payload, require_bearer = true, toast = null, custom_message = null) => {

        try{

            const response = 
                await fetch(
                    `${Api.Endpoint}${path}`,
                    {
                        method: "POST",
                        headers: !require_bearer ? {'Content-Type': 'application/json'} : {'Authorization': Api.Bearer, 'Content-Type': 'application/json'},
                        body: JSON.stringify(payload)
                    }
                );
            if(response.status == 200){
                const json = await response.json();
                if(json.status){
                    return json;
                }
            }

        }catch(e){
            console.log("Error", e);
        }

        if(toast){
            showMessage(toast, "error", custom_message ?? "Si è verificato un errore durante la richiesta. Riprova..");
        }

        return null;
    }

};

export default Api;