import Api from "./api";

const Utility = {

    vr: "0.1",

    getSkip: (location) => {
        try{
            let skip = "0";
            let search = location.search.replace("?","");
            if(search.includes("&")){
                search = search.split("&")[0]
            }
            if(search){
                skip = search.split("skip=").join("").trim();
                return skip;
            }
        }catch(_){}
        return "0";
    },

    resetSkip: (page, setSkip) => {
        const url = new URL(window.location.href);
        url.searchParams.set("skip", page.toString());
        window.history.pushState(null, '', url);
        if(setSkip){
            setSkip(page);
        }
    },

    restoreSearch: (notAppendKeys) => {
        let search = window.location.search.split("?")[1].split("&");
        let searchToAppend = '';
        for(let s = 0; s < search.length; s++){
            if(!notAppendKeys.includes(search[s].split("=")[0].trim())){
                searchToAppend += search[s] + "&";
            }
        }
        searchToAppend = searchToAppend.substring(0, searchToAppend.length - 1);
        return searchToAppend;
    },

    getQueryParam: (keySearch) => {
        const search = window.location.search;
        let value = null;
        try{
            if(search){
                const keys = search.split("?")[1].split("&");
                for(let key of keys){
                    if(key.split("=")[0].trim() == keySearch.trim()){
                        value = key.split("=")[1];
                    }
                }
            }
        }catch(_){}
        return value;
    },

    hasId: (ids, id) => {
        const index = ids.findIndex(i => i.toString() == id.toString());
        return index > -1;
    },

    hasIdIndex: (ids, id) => {
        const index = ids.findIndex(i => i.toString() == id.toString());
        return index;
    },

    addIdToRemove: (ids, id) => {
        if(ids){
            const index = ids.findIndex(i => i.toString() == id.toString());
            if(index > -1){
                ids.splice(index, 1);
            }else{
                ids.push(id);
            }
        }
        return JSON.parse(JSON.stringify(ids ?? []));
    },

    getDifferenceBetweenTwoDatesInDays: (d1, d2) => {
        return parseInt((d2 - d1) / (1000 * 60 * 60 * 24), 10); 
    },

    actionCheckBoxRemove: (e, records, ids, setIds) => {

        let toDelete = [];
        (records ?? []).forEach(item => {
            if(e.target.checked){
                if(!Utility.hasId(ids, item.id)){
                    ids.push(item.id);
                }
            }else{
                let index = -1;
                if((index = Utility.hasIdIndex(ids, item.id)) > -1){
                    if(index > -1){
                        toDelete.push(item.id);
                    }
                }
            }
        });
        if(toDelete.length > 0){
            toDelete.forEach(d => {
                let index = Utility.hasIdIndex(ids, d);
                if(index > -1){
                    ids.splice(index, 1);
                }
            });
        }

        setIds(JSON.parse(JSON.stringify(ids)));

    },

    isAssignablePermission: (permission) => {
        console.log(Api.Permissions);
    },

    permissionModel: () => {
        
        const permissions = 
            [

                // Utente
                {
                    "key": "create_user", "value": 0, "title": "Crea utenti", "area": "Utenti", "icon": '<i class="bi bi-person"></i>', 'enabled': true
                },
                {
                    "key": "read_user", "value": 0, "title": "Leggi utenti", 'enabled': true
                },
                {
                    "key": "delete_user", "value": 0, "title": "Cancella utenti", 'enabled': true
                },

                // Licenze
                {
                    "key": "create_license", "value": 0, "title": "Crea licenze", "area": "Licenze", "icon": '<i class="bi bi-receipt"></i>', 'enabled': true
                },
                {
                    "key": "read_license", "value": 0, "title": "Leggi licenze", 'enabled': true
                },
                {
                    "key": "delete_license", "value": 0, "title": "Cancella licenze", 'enabled': true
                },

                // Permessi
                {
                    "key": "create_permission", "value": 0, "title": "Crea permessi", "area": "Permessi", "icon": '<i class="bi bi-shield-exclamation"></i>', 'enabled': true
                },
                {
                    "key": "read_permission", "value": 0, "title": "Leggi permessi", 'enabled': true
                },
                {
                    "key": "delete_permission", "value": 0, "title": "Cancella permessi", 'enabled': true
                },

                // Ciclo attivo
                {
                    "key": "read_active_movement", "value": 0, "title": "Lettura ciclo attivo", "area": "Ciclo attivo", "icon": '<i class="bi bi-coin"></i>', 'enabled': true
                },

                // Ciclo passivo
                {
                    "key": "read_passive_movement", "value": 0, "title": "Lettura ciclo passivo", "area": "Ciclo passivo", "icon": '<i class="bi bi-wallet2"></i>', 'enabled': true
                },

                // Documenti
                {
                    "key": "create_document", "value": 0, "title": "Creazione documento", "area": "Documenti", "icon": '<i class="bi bi-sd-card"></i>', 'enabled': true
                },
                {
                    "key": "read_document", "value": 0, "title": "Leggi documento", 'enabled': true
                },
                {
                    "key": "delete_document", "value": 0, "title": "Cancella documento", 'enabled': true
                },

                // Pings
                {
                    "key": "read_pings", "value": 0, "title": "Leggi pings", "area": "Pings", "icon": '<i class="bi bi-cloud-lightning"></i>', 'enabled': true
                }

            ];
        
        let workedPermissions = [];
        permissions.forEach(k => {
            if(Api.Permissions[k.key] == 0){
                k.enabled = false;
            }
            workedPermissions.push(k);
        });

        return workedPermissions;
    },

    getCausalName: (causal) => {
        switch(causal){
            case "ASSIGN_LICENSE": {
                return "ASSEGNAZIONE LICENZE";
            };
            case "CREATE_LICENSE": {
                return "CREAZIONE LICENZA";
            };
            case "RENEW_LICENSE": {
                return "RINNOVO LICENZA";
            };
        }
        return "";
    },

    getCausalColorActive: (causal) => {
        switch(causal){
            case "ASSIGN_LICENSE": {
                return "green.400";
            };
            case "CREATE_LICENSE": {
                return "orange.400";
            };
            case "RENEW_LICENSE": {
                return "blue.400";
            };
        }
        return "";
    },

    getCausalColorPassive: (causal) => {
        switch(causal){
            case "ASSIGN_LICENSE": {
                return "red.400";
            };
            case "CREATE_LICENSE": {
                return "orange.400";
            };
            case "RENEW_LICENSE": {
                return "blue.400";
            };
        }
        return "";
    },

    getLicenseStatus: (status) => {
        switch(status){
            case 0: {
                return "DA ATTIVARE"
            };
            case 1: {
                return "ATTIVA"
            };
            case 2: {
                return "BLOCCATA"
            };
            case 3: {
                return "TRIAL"
            };
        }
    },

    getLicenseColor: (status) => {
        switch(status){
            case 0: {
                return "orange.400"
            };
            case 1: {
                return "green.400"
            };
            case 2: {
                return "red.400"
            };
            case 3: {
                return "yellow.400"
            };
        }
    },

    getHistoryStatusName: (status) => {
        switch(status){
            case "change_status": {
                return "CAMBIO STATO";
            }
            case "change_price": {
                return "CAMBIO PREZZO"
            };
            case "renew": {
                return "RINNOVO LICENZA"
            };
        }
    },

    loadCustomerBySearch: async (refCustomer, setCustomers, getResellers = false) => {
        if(refCustomer.current.value && refCustomer.current.value.length >= 3){
            const response = await Api.Get(`/api/v1/reseller/get_customer_by_search?search=${refCustomer.current.value}${getResellers ? `&get_reseller=1` : ``}`, true);
            if(response){
                if(response.data && response.data.customers && response.data.customers.length > 0){
                    let cstms = [];
                    (response.data.customers ?? []).forEach(c => {
                        const index = cstms.findIndex(cs => cs.id == c.id);
                        if(index == -1){
                            cstms.push(c);
                        }
                    });
                    setCustomers(cstms);
                    return;
                }
            }
        }
        setCustomers([]);
    },

    getBaseFolder: () => {
        return "/web/license/filesystem";
    },

    getPlatformIcon: (platform) => {
        switch(platform){
            case "web": {
                return <i class="bi bi-browser-chrome"></i>;
            }
            case "windows": {
                return <i class="bi bi-windows"></i>;
            };
            case "android": {
                return <i class="bi bi-android2"></i>;
            };
            case "macos": {
                return <i class="bi bi-apple"></i>;
            };
            case "ios": {
                return <i class="bi bi-apple"></i>;
            };
        }
    }

};

export default Utility;