const showMessage = (toast, type, description, duration = 3000) => {
    toast({
        title       : type == "info" ? "Messaggio" : "Attenzione",
        description : description,
        status      : type,
        isClosable  : true,
        duration    : duration,
        position    : "top-center"
    });
};

export default showMessage;