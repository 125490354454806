import { Center, Flex, Spinner, Text } from "@chakra-ui/react";

const Wait = () => {
    return (
        <Flex flexDir={"column"} justifyContent={"center"} h={"100vh"} className="animate__animated animate__fadeIn animate__faster">
            <Center display={"flex"} flexDir={"column"} gap={"30px"}>
                <Spinner size={"xl"}/>
                <Text fontSize={"xl"}>Caricamento in corso..</Text>
            </Center>
        </Flex>
    );
};

export default Wait;