import { Button, Flex, Select, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import Utility from "../core/utility";

const Pagination = ({pages, currentPage, pagination, movePage}) => {

    return (
        <Flex flexDir={"column"} gap={"10px"}>
            <div className="pagination">
                <Select onChange={(e) => {
                    localStorage.setItem(`__pagination_${pagination}`, e.target.value);
                    const restored = Utility.restoreSearch(["skip"]);
                    window.location.search = `?skip=0&${restored}`;
                }} value={localStorage.getItem(`__pagination_${pagination}`)}>
                    <option value={"5"}>5</option>
                    <option value={"10"}>10</option>
                    <option value={"20"}>20</option>
                    <option value={"50"}>50</option>
                    <option value={"100"}>100</option>
                </Select>
                {
                    pages > 1 ? 
                        <Button onClick={() => {
                            if(currentPage > 0){
                                movePage(currentPage - 1);
                            }
                        }}><i class="bi bi-chevron-double-left"></i></Button>
                    :
                        <></>
                }
                <Button className={currentPage == 0 ? "button-pagination-active" : ""} onClick={() => movePage(0)}>1</Button>
                {
                    pages >= 2 ? 
                        <Button className={currentPage == 1 ? "button-pagination-active" : ""} onClick={() => movePage(1)}>2</Button>
                    :
                        <></>
                }
                {
                    pages >=4 ? 
                        <>
                            <Button>...</Button>
                            {
                                (pages > 4 && currentPage < (pages - 2) && currentPage >= 2) ? 
                                    <>
                                        <Button className="button-pagination-active">{currentPage + 1}</Button>
                                        <Button>...</Button>
                                    </>
                                :
                                    <></>
                            }
                            <Button className={currentPage == (pages - 2) ? "button-pagination-active" : ""} onClick={() => movePage(pages - 2)}>{pages - 1}</Button>
                            <Button className={currentPage == (pages - 1) ? "button-pagination-active" : ""} onClick={() => movePage(pages - 1)}>{pages}</Button>
                        </>
                    :   
                        <></>
                }
                {
                    pages <= 3 && pages > 2 ? 
                        <Button  className={currentPage == 2 ? "button-pagination-active" : ""}  onClick={() => movePage("2")}>3</Button>
                    :
                        <></>
                }
                {
                    pages > 1 ? 
                        <Button onClick={() => {
                            if(currentPage + 1 < pages){
                                movePage(currentPage + 1);
                            }
                        }}><i class="bi bi-chevron-double-right"></i></Button>
                    :
                        <></>
                }
            </div>
            {
                pages > 1 ? 
                    <Text fontSize={"sm"}>Pagina {parseInt(currentPage) + 1} di {pages}</Text>
                :
                    <></>
            }
        </Flex>
    );
};

export default Pagination;