import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Button, Container, Divider, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Flex, Image, Menu, MenuButton, MenuItem, MenuList, Text } from "@chakra-ui/react";
import Core from "./core";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Api from "./api";
import ModalConfirm from "../components/modal_confirm";
import { ChevronDownIcon } from '@chakra-ui/icons'
import Utility from "./utility";


const Layout = ({children, active, breadcrumbs}) => {

    const navigate                  = useNavigate();
    const [isOpen, setIsOpen]       = useState(false);

    const MenuItems = () => {
        return (
            <>
                <div className="container-menu-voice">
                    <Flex className={`row-menu${active == "dashboard" ? " active" : ""}`} justifyContent={"space-between"} onClick={() => navigate("/dashboard")}>
                        <div className="icon"><i class="bi bi-speedometer2"></i></div>
                        <div className="text">Dashboard</div>
                    </Flex>
                </div>
                {
                    Api.Permissions && Api.Permissions.read_user == 1 ?
                        <div className="container-menu-voice">
                            <Flex className={`row-menu${active == "reseller" ? " active" : ""}`} justifyContent={"space-between"} onClick={() => {
                                const filterEntity = localStorage.getItem('__filter_entity_type_reseller');
                                navigate(`/resellers?skip=0&orderDirection=${localStorage.getItem('__order_by_reseller')}&orderFilter=${localStorage.getItem('__filter_reseller')}${filterEntity ? `&entityFilter=${filterEntity}` : ``}`);
                            }}>
                                <div className="icon"><i class="bi bi-diagram-3"></i></div>
                                <div className="text">Reseller</div>
                            </Flex>
                        </div>
                    :
                        <></>
                }
                {
                    Api.Permissions && Api.Permissions.read_permission == 1 ?
                        <div className="container-menu-voice">
                            <Flex className={`row-menu${active == "permission_model" ? " active" : ""}`} justifyContent={"space-between"} onClick={() => {
                                navigate(`/permission?skip=0&orderDirection=${localStorage.getItem('__order_by_permission_model')}&orderFilter=${localStorage.getItem('__filter_permission_model')}`);
                            }}>
                                <div className="icon"><i class="bi bi-shield-exclamation"></i></div>
                                <div className="text">Permessi</div>
                            </Flex>
                        </div>
                    :
                        <></>
                }
                {
                    Api.Permissions && Api.Permissions.read_user == 1 ?
                        <div className="container-menu-voice">
                            <Flex className={`row-menu${active == "reseller_login" ? " active" : ""}`} justifyContent={"space-between"} onClick={() => {
                                navigate(`/users?skip=0&orderDirection=${localStorage.getItem('__order_by_permission_model')}&orderFilter=${localStorage.getItem('__filter_permission_model')}`);
                            }}>
                                <div className="icon"><i class="bi bi-person"></i></div>
                                <div className="text">Utenti</div>
                            </Flex>
                        </div>
                    :
                        <></>
                }
                {
                    Api.Permissions && Api.Permissions.read_license == 1 ?
                        <div className="container-menu-voice">
                            <Flex className={`row-menu${active == "license" ? " active" : ""}`} justifyContent={"space-between"} onClick={() => {
                                navigate(`/license?skip=0&orderDirection=${localStorage.getItem('__order_by_license')}&orderFilter=${localStorage.getItem('__filter_license')}`);
                            }}>
                                <div className="icon"><i class="bi bi-receipt"></i></div>
                                <div className="text">Licenze</div>
                            </Flex>
                        </div>
                    :
                        <></>
                }
                {
                    Api.Permissions && Api.Permissions.read_active_movement == 1 ?
                        <div className="container-menu-voice">
                            <Flex className={`row-menu ${active == "movement_active" ? "active" : ""}`} justifyContent={"space-between"} onClick={() => {
                                navigate(`/movement_active?skip=0&orderDirection=${localStorage.getItem('__order_by_movement_active')}&orderFilter=${localStorage.getItem('__filter_movement_active')}`);
                            }}>
                                <div className="icon"><i class="bi bi-coin"></i></div>
                                <div className="text">Ciclo attivo</div>
                            </Flex>
                        </div>
                    :
                        <></>
                }
                {
                    Api.Permissions && Api.Permissions.read_passive_movement == 1 ?
                        <div className="container-menu-voice">
                            <Flex className={`row-menu ${active == "movement_passive" ? "active" : ""}`} justifyContent={"space-between"} onClick={() => {
                                navigate(`/movement_passive?skip=0&orderDirection=${localStorage.getItem('__order_by_movement_passive')}&orderFilter=${localStorage.getItem('__filter_movement_passive')}`);
                            }}>
                                <div className="icon"><i class="bi bi-wallet2"></i></div>
                                <div className="text">Ciclo passivo</div>
                            </Flex>
                        </div>
                    :
                        <></>
                }
                {
                    Api.Permissions && Api.Permissions.create_document == 1 ? 
                        <div className="container-menu-voice">
                            <Flex className={`row-menu ${active == "document" ? "active" : ""}`} justifyContent={"space-between"} onClick={() => {
                                navigate(`/document?skip=0&orderDirection=${localStorage.getItem('__order_by_document')}&orderFilter=${localStorage.getItem('__filter_document')}`);
                            }}>
                                <div className="icon"><i class="bi bi-sd-card"></i></div>
                                <div className="text">Estratti conto</div>
                            </Flex>
                        </div>
                    :
                        <></>
                }
                {
                    Api.Permissions && Api.Permissions.read_pings == 1 ? 
                        <div className="container-menu-voice">
                            <Flex className={`row-menu ${active == "pings" ? "active" : ""}`} justifyContent={"space-between"} onClick={() => {
                                navigate(`/pings?skip=0`);
                            }}>
                                <div className="icon"><i class="bi bi-cloud-lightning"></i></div>
                                <div className="text">Pings</div>
                            </Flex>
                        </div>
                    :
                        <></>
                }
                {
                    window.innerWidth < 1000 ? 
                        <div className="container-menu-voice">
                            <Flex className="row-menu" justifyContent={"space-between"} onClick={() => {
                                window.location.hash = "#want_exit";
                            }}>
                                <div className="icon"><i class="bi bi-box-arrow-left"></i></div>
                                <div className="text">Esci</div>
                            </Flex>
                        </div>
                    :
                        <></>
                }
                {
                    window.innerWidth < 1000 ? 
                        <div className="copyright-mobile">
                            2023 © CMH s.r.l. | Tutti i diritti riservati | vr{Utility.vr}
                        </div>
                    :
                        <></>
                }
            </>
        );
    };

    return (
        <Flex className="layout animate__animated animate__fadeIn animate__faster">

            {
                // Copyright
            }
            <div className="copyright">
                2023 © {Core.Enterprise} | Tutti i diritti riservati | vr{Utility.vr}
            </div>

            {
                // Modal di uscita
            }
            <ModalConfirm message={"Sei sicuro di voler uscire?"} isOpen={window.location.hash == "#want_exit"} onClose={() => window.location.hash = ""} ok={() => {
                localStorage.removeItem('__bearer');
                localStorage.removeItem('__id');
                localStorage.removeItem('__id_user');
                window.location.href = "/";
            }}></ModalConfirm>

            {
                // DrawerMobile
                window.innerWidth < 1000 ? 
                    <Drawer isOpen={isOpen} placement='right' onClose={() => setIsOpen(false)}>
                        <DrawerOverlay />
                        <DrawerContent>
                            <DrawerCloseButton />
                            <DrawerHeader>Menù</DrawerHeader>
                            <DrawerBody className="menu-drawer">
                                <MenuItems/>
                            </DrawerBody>
                        </DrawerContent>
                    </Drawer>
                :
                    <></>
            }
            <div className="sidebar">
                <div className="header">
                    <Image src={Core.Logo}/>
                </div>
                <div className="body">
                    <MenuItems/>
                </div>
            </div>
            <div className="content">
                <Flex className="top-bar-mobile" justifyContent={"space-between"}>
                    <Flex flexDir={"column"} justifyContent={"center"} paddingLeft={"10px"}>
                        <Image src={Core.Logo} height={"40px"}/>
                    </Flex>
                    <Flex flexDir={"column"} justifyContent={"center"} paddingRight={"10px"}>
                        <div onClick={() => setIsOpen(true)}>
                            <i class="bi bi-list" style={{fontSize: "30px"}}></i>
                        </div>
                    </Flex>
                </Flex>
                <div className="body">
                    <Flex justifyContent={window.innerWidth < 1000 ? "start" : "space-between"}>
                        {
                            breadcrumbs && breadcrumbs.length > 0 ?
                                <Breadcrumb lineHeight={"50px"} spacing='8px' separator={<ChevronRightIcon color='gray.500' />}>
                                    {
                                        breadcrumbs.map((item, index) => 
                                            <BreadcrumbItem>
                                                <BreadcrumbLink href='#' onClick={() => {
                                                    if(item.navigation){
                                                        navigate(item.navigation);
                                                    }
                                                    return false;
                                                }}>{item.value}</BreadcrumbLink>
                                            </BreadcrumbItem>
                                        )
                                    }
                                </Breadcrumb>
                            :
                                <></>
                        }
                        {
                            window.innerWidth > 1000 ? 
                                <Menu>
                                    <MenuButton  as={Button} rightIcon={<ChevronDownIcon />}>
                                        Ciao, {Api.BusinessName ?? ""}
                                    </MenuButton>
                                    <MenuList>
                                        <MenuItem onClick={() => window.location.hash = "#want_exit"}>Esci</MenuItem>
                                    </MenuList>
                                </Menu>
                            :
                                <></>
                        }
                    </Flex>
                    {children}
                </div>
            </div>
        </Flex>
    );
};

export default Layout;