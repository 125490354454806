import { Button, Divider, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useToast } from "@chakra-ui/react";
const ModalConfirm = ({isOpen, onClose, ok, message}) => {

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={"2xl"}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Attenzione</ModalHeader>
                <ModalCloseButton />
                <Divider/>
                <ModalBody>
                    <Text>{message}</Text>
                </ModalBody>
                <Divider/>
                <ModalFooter gap={"10px"}>
                    <Button onClick={async () => {
                        onClose();
                    }}>Annulla</Button>
                    <Button bg={"var(--red)"} color={"white"} onClick={async () => {
                        ok();
                    }}>Procedi</Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ModalConfirm;