const Core = {
    whitelabel  : "cmh",
    Enterprise  : "CMH S.r.l",
    Logo        : "https://www.cmh.it/img/cmh-logo-extended.png",
    Softwares   : [],
    initWL      : () => {
        if(window.location.hostname){
            switch(window.location.hostname){
              case "localhost": {
                Core.Enterprise = "CMH S.r.l";
              } break;
              case "licensing.cmh.it": {
                Core.Enterprise = "CMH S.r.l";
              } break;
              case "licensing.dylogapp.it": {

                Core.whitelabel = "dylog";
                Core.Enterprise = "Dylog Italia Spa";
                Core.Logo = "https://dylogapp.it/mask_layout/dylog/easyappear-logo-scritta-orizzontale.svg";
                document.title = "Dylog Italia Spa";
                document.querySelector('#icon_licensing_app').setAttribute("href", "https://dylogapp.it/mask_layout/dylog/favicon.png");

                document.documentElement.style.setProperty('--main-color', "#00883336");
                document.documentElement.style.setProperty('--main-color-strong', "#00883382");
                document.documentElement.style.setProperty('--chakra-colors-gray-200', '#0088330a');
                document.documentElement.style.setProperty('--chakra-colors-gray-100', '#0088330a');
                document.documentElement.style.setProperty('--border-color-input', "#00883382");
                document.documentElement.style.setProperty('--chakra-colors-blue-500', "#00883382");
                document.documentElement.style.setProperty('--chakra-colors-blue-600', "#00883382");

              } break;
            }
        }
    },
    paginationsAndFilter: () => {

      // =================
      // Reseller
      // =================

      const paginationReseller  = localStorage.getItem('__pagination_reseller');
      const orderByReseller     = localStorage.getItem('__order_by_reseller');
      const filterReseller      = localStorage.getItem('__filter_reseller');
      
      if(!paginationReseller){
        localStorage.setItem('__pagination_reseller', "100");
      }
      if(!orderByReseller){
        localStorage.setItem('__order_by_reseller', "desc");
      }
      if(!filterReseller){
        localStorage.setItem('__filter_reseller', "id");
      }

      // =================
      // Permessi
      // =================

      const paginationPermissionModel  = localStorage.getItem('__pagination_permission_model');
      const orderByPermissionModel     = localStorage.getItem('__order_by_permission_model');
      const filterPermissionModel      = localStorage.getItem('__filter_permission_model');
      
      if(!paginationPermissionModel){
        localStorage.setItem('__pagination_permission_model', "100");
      }
      if(!orderByPermissionModel){
        localStorage.setItem('__order_by_permission_model', "desc");
      }
      if(!filterPermissionModel){
        localStorage.setItem('__filter_permission_model', "id");
      }

      // =================
      // Utenze
      // =================

      const paginationResellerLogin  = localStorage.getItem('__pagination_reseller_login');
      const orderByResellerLogin     = localStorage.getItem('__order_by_reseller_login');
      const filterResellerLogin      = localStorage.getItem('__filter_reseller_login');
      
      if(!paginationResellerLogin){
        localStorage.setItem('__pagination_reseller_login', "100");
      }
      if(!orderByResellerLogin){
        localStorage.setItem('__order_by_reseller_login', "desc");
      }
      if(!filterResellerLogin){
        localStorage.setItem('__filter_reseller_login', "id");
      }

      // =================
      // Movimenti attivi
      // =================

      const paginationMovementActive  = localStorage.getItem('__pagination_movement_active');
      const orderByMovementActive     = localStorage.getItem('__order_by_movement_active');
      const filterMovementActive      = localStorage.getItem('__filter_movement_active');
      
      if(!paginationMovementActive){
        localStorage.setItem('__pagination_movement_active', "100");
      }
      if(!orderByMovementActive){
        localStorage.setItem('__order_by_movement_active', "desc");
      }
      if(!filterMovementActive){
        localStorage.setItem('__filter_movement_active', "id");
      }

      // =================
      // Movimenti passivi
      // =================

      const paginationMovementPassive  = localStorage.getItem('__pagination_movement_passive');
      const orderByMovementPassive     = localStorage.getItem('__order_by_movement_passive');
      const filterMovementPassive      = localStorage.getItem('__filter_movement_passive');
      
      if(!paginationMovementPassive){
        localStorage.setItem('__pagination_movement_passive', "100");
      }
      if(!orderByMovementPassive){
        localStorage.setItem('__order_by_movement_passive', "desc");
      }
      if(!filterMovementPassive){
        localStorage.setItem('__filter_movement_passive', "id");
      }

      // =================
      // Licenze
      // =================

      const paginationLicense  = localStorage.getItem('__pagination_license');
      const orderByLicense     = localStorage.getItem('__order_by_license');
      const filterLicense      = localStorage.getItem('__filter_license');
      
      if(!paginationLicense){
        localStorage.setItem('__pagination_license', "100");
      }
      if(!orderByLicense){
        localStorage.setItem('__order_by_license', "desc");
      }
      if(!filterLicense){
        localStorage.setItem('__filter_license', "id");
      }

      // =================
      // Documenti
      // =================

      const paginationDocument  = localStorage.getItem('__pagination_document');
      const orderByDocument     = localStorage.getItem('__order_by_document');
      const filterDocument      = localStorage.getItem('__filter_document');
      
      if(!paginationDocument){
        localStorage.setItem('__pagination_document', "100");
      }
      if(!orderByDocument){
        localStorage.setItem('__order_by_document', "desc");
      }
      if(!filterDocument){
        localStorage.setItem('__filter_document', "id");
      }

      // =================
      // Pings
      // =================

      const paginationPings  = localStorage.getItem('__pagination_pings');
      const orderByPings     = localStorage.getItem('__order_by_pings');
      const filterPings      = localStorage.getItem('__filter_pings');
      
      if(!paginationPings){
        localStorage.setItem('__pagination_pings', "100");
      }
      if(!orderByPings){
        localStorage.setItem('__order_by_pings', "desc");
      }
      if(!filterPings){
        localStorage.setItem('__filter_pings', "id");
      }

    }
};

export default Core;