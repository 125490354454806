import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import Core from './core/core';
import Wait from './components/wait';
import { v4 as uuidv4 } from 'uuid';
import './index.css';
import 'animate.css';
import Api from './core/api';
import Pings from './pages/pings';

const Login           = React.lazy(() => import("./pages/login"));
const Dashboard       = React.lazy(() => import("./pages/dashboard"));
const Reseller        = React.lazy(() => import("./pages/reseller"));
const PermissionModel = React.lazy(() => import("./pages/permission_model"));
const ResellerLogin   = React.lazy(() => import("./pages/reseller_login"));
const Movement        = React.lazy(() => import("./pages/movement"));
const License         = React.lazy(() => import("./pages/license"));
const Document        = React.lazy(() => import("./pages/document"));

if(window.location.pathname != "/"){
  localStorage.setItem('__last_path', window.location.pathname + (window.location.search ?? ""));
  window.location.href = "/";
}

const root = ReactDOM.createRoot(document.getElementById('root'));
Core.initWL();
Core.paginationsAndFilter();

// Ottieni suite di softwares
Api.Get("/api/v1/suite/read_suite_of_software", false).then(response => {
  if(response && response.status){

    // ristruttura whitelabel
    let sws = [];
    response.data.softwares.forEach(s => {
      if(s.white_label.includes(Core.whitelabel)){
        sws.push(s);
      }
    });

    Core.Softwares = sws;
  }
});

root.render(
  <ChakraProvider>
    <BrowserRouter basename={window.location.pathname}>
      <Routes>
        <Route path='/' element={<Suspense fallback={<Wait/>}><Login/></Suspense>}></Route>
        <Route path='/dashboard' element={<Suspense fallback={<Wait/>}><Dashboard/></Suspense>}></Route>
        <Route path='/resellers' element={<Suspense fallback={<Wait/>}><Reseller/></Suspense>}></Route>
        <Route path='/permission' element={<Suspense fallback={<Wait/>}><PermissionModel/></Suspense>}></Route>
        <Route path='/users' element={<Suspense fallback={<Wait/>}><ResellerLogin/></Suspense>}></Route>
        <Route path='/movement_active' element={<Suspense fallback={<Wait/>}><Movement movementType={"active"}  key={uuidv4()}/></Suspense>}></Route>
        <Route path='/movement_passive' element={<Suspense fallback={<Wait/>}><Movement movementType={"passive"} key={uuidv4()}/></Suspense>}></Route>
        <Route path='/license' element={<Suspense fallback={<Wait/>}><License/></Suspense>}></Route>
        <Route path='/document' element={<Suspense fallback={<Wait/>}><Document/></Suspense>}></Route>
        <Route path='/pings' element={<Suspense fallback={<Wait/>}><Pings/></Suspense>}></Route>
      </Routes>
    </BrowserRouter>
  </ChakraProvider>
);
