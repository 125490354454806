import { Badge, Button, Card, CardBody, CardHeader, Center, Checkbox, Divider, Flex, Input, InputGroup, InputLeftElement, InputRightElement, Select, Spinner, Table, Tag, Tbody, Td, Text, Th, Thead, Tr, useToast } from "@chakra-ui/react";
import Layout from "../core/layout";
import Core from "../core/core";
import { useEffect, useRef, useState } from "react";
import Api from "../core/api";
import { useLocation } from "react-router-dom";
import Utility from "../core/utility";
import Pagination from "../components/pagination";

const Pings = ({movementType}) => {

    const toast                             = useToast();
    const [didMount, setDidMount]           = useState(true);
    const [render, setRender]               = useState(false);
    const location                          = useLocation();
    const [loading, setLoading]             = useState(true);
    const [skip, setSkip]                   = useState(null);
    const [records, setRecords]             = useState([]);
    const [pages, setPages]                 = useState(0);
    const [search, setSearch]               = useState(Utility.getQueryParam("search") ?? "");
    const [tempSearch, setTempSearch]       = useState(null);

    // Filters
    const [orderByDirection, setOrderByDirection]   = useState(Utility.getQueryParam("orderDirection")  ?? "desc"   );
    const [filterOrderBy, setFilterOrderBy]         = useState(Utility.getQueryParam("orderFilter")     ?? "id"     );

    const load = (skip, search = null, orderBy = null, sortField = null) => {
        setRecords([]);
        setLoading(true);
        Api.Get(`/api/v1/pings/get_pings?skip=${skip}&take=${localStorage.getItem(`__pagination_pings`)}${search ? `&search=${search}` : ``}`).then(response => {
            setLoading(false);
            if(response && response.status && response.data && response.data.page && response.data.page.length > 0){
                setRecords(response.data.page);
                setPages(response.data.pages);
            }
        });
    };

    useEffect(() => {        
        setSkip( Utility.getSkip(location) ?? "0" );
        setDidMount(false);
    }, []); 


    useEffect(() => {
        if(!didMount){
            if(Utility.getSkip(location).toString() != skip.toString()){
                setSkip(Utility.getSkip(location) ?? "0");
            }
        }
    }, [location.search]);

    useEffect(() => {
        if(skip || skip == 0){
            load(skip, search, orderByDirection, filterOrderBy);
        }
    }, [skip]);

    return (
        <Layout breadcrumbs={[{value: Core.Enterprise},{value: "Pings"}]} active={`pings`}>

            <Card>
                <CardHeader>
                    <Flex justifyContent={"space-between"} flexDir={window.innerWidth < 1000 ? "column" : "row"}>
                        <div>
                            <Text fontSize={window.innerWidth < 1000 ? "x-large" : "xx-large"}>&nbsp;&nbsp;Pings</Text>
                        </div>
                        {
                            window.innerWidth < 1000 ? 
                                <Divider/>
                            :
                                <></>
                        }
                        {
                            window.innerWidth < 1000 ? 
                                <Divider/>
                            :
                                <></>
                        }
                    </Flex>
                    <Flex gap={"20px"} marginTop={"20px"}>
                        <InputGroup cursor={"pointer"}>
                            <InputRightElement height={"50px"} onClick={() => {
                                const restored = Utility.restoreSearch(["search", "skip"]);
                                window.location.search = `?skip=0${tempSearch ? `&search=${tempSearch}` : ``}&${restored}`;
                            }}>
                                <i class="bi bi-search" style={{fontSize: "25px"}}></i>
                            </InputRightElement>
                            <Input height={"50px"} variant={"filled"} placeholder="Ricerca.." value={tempSearch} onChange={(e) => {
                                setTempSearch(e.target.value);
                            }} onKeyDown={(e) => {
                                if(e.key.toLocaleLowerCase() == "enter"){
                                    const restored = Utility.restoreSearch(["search", "skip"]);
                                    window.location.search = `?skip=0${tempSearch ? `&search=${tempSearch}` : ``}&${restored}`;
                                }
                            }}/>
                        </InputGroup>
                    </Flex>
                    {
                        search ? 
                            <Text class="cancel-search" onClick={() => {
                                const restored = Utility.restoreSearch(["skip", "search"]);
                                window.location.search = `?skip=0&${restored}`;
                            }} ><i class="bi bi-x-lg"></i>&nbsp;&nbsp;Ricerca per "{decodeURI(search)}"</Text>
                        :
                            <></>
                    }
                </CardHeader>
                <Divider/>
                <CardBody>
                    <div className="body-table">
                        <Table variant={"striped"} size={"md"}>
                            <Thead>
                                <Th>Ragione sociale</Th>
                                <Th>P.IVA</Th>
                                <Th>Software</Th>
                                <Th>Accessi</Th>
                                <Th>Ultimo ping</Th>
                            </Thead>
                            <Tbody>
                                {
                                    records.length > 0 ?
                                        records.map((item, index) => 
                                            <Tr>
                                                <Td>{item.business_name}</Td>
                                                <Td>{item.vat_number}</Td>
                                                <Td>{item.software}</Td>
                                                <Td>{item.ping_counts}</Td>
                                                <Td>{new Date(item.last_ping).toLocaleString()}</Td>
                                            </Tr>
                                        )
                                    :
                                        <></>
                                }
                            </Tbody>
                        </Table>
                    </div>
                    {
                        loading ? 
                            <Center padding={"10"} display={"flex"} flexDir={"column"} gap={"20px"}>
                                <Spinner size={"lg"}/>
                                <Text>Caricamento in corso..</Text>
                            </Center>
                        :
                            <>
                                {
                                    records.length == 0 && !loading ? 
                                        <Center padding={"10"} display={"flex"} flexDir={"column"} gap={"5px"}>
                                            <Text>
                                                Non ci sono records..
                                            </Text>
                                        </Center>
                                    :
                                        <></>
                                }
                            </>
                    }
                    {
                        pages > 0 && records.length > 0 ?
                            <>
                                <div style={{height: "50px"}}/>
                                <Flex justifyContent={"end"}>
                                    <Pagination pages={pages} currentPage={parseInt(skip)} pagination={`pings`} movePage={(page) => {
                                        Utility.resetSkip(page, setSkip);
                                    }}/>
                                </Flex>
                            </>
                        :
                            <></>
                    }
                </CardBody>
            </Card>
        </Layout>
    );
};

export default Pings;